import React from 'react';

// import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Header.css';
import { useTranslation } from 'react-i18next';

function Header() {
  const { t } = useTranslation();

  return (
    <div className='app__header app__wrapper section__padding' id='home'>
      <div className='app__wrapper_info'>
        {/* <SubHeading title='Chase the new flavour' /> */}
        <h1 className='app__header-h1'>{t('welcome')}</h1>
        <p className='p__opensans' style={{ margin: '1rem 0' }}>
          {t('welcome_desc')}{' '}
        </p>
        <p style={{ color: 'red', margin: '2rem 0', fontSize: '22px', fontFamily: 'sans-serif' }}>
          {t('lunch_buffet')}{' '}
        </p>
        <a href='/menu' target='_blank' rel=''>
          <button type='button' className='custom__button'>
            {t('view_menu')}
          </button>
        </a>
      </div>

      <div className='app__home_img'>
        <img src={images.welcome} alt='header_img' />
      </div>
    </div>
  );
}

export default Header;
