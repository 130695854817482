import images from './images';

const wines = [
  {
    title: 'CHICKEN CURRY (L, G, M)',
    price: '17.50€',
    tags: 'Kananpaloja, inkivääriä ja tuoretta korianteria miedossa currykastikkeessa',
    tags1:
      'Pieces of Chicken, ginger and fresh coriander in a spicy curry sauce',
  },
  {
    title: 'CHICKEN MASALA KARAHI (G)',
    price: '19.90€',
    tags: 'Yrttijogurtissa marinoituja tandooripaistettuja kanan rintapaloja masalakastikkeessa, tulinen',
    tags1:
      'Herb and yoghurt marinated tandoori chicken tikka in a hot, spicy masala sauce',
  },
  {
    title: 'CHICKEN KORMA (L, G)',
    price: '17.50€',
    tags: 'Kananpaloja miedossa curry-kermakastikkeessa',
    tags1: 'Chicken pieces in a mild curry cream sauce',
  },
  {
    title: 'LAMB CURRY (L, G, M)',
    price: '18.50€',
    tags: 'Lammasta miedosti tulisessa talon currykastikkeessa',
    tags1: 'Lamb in a spicy house curry sauce',
  },
  {
    title: 'SHAHI PANEER (G)',
    price: '17.90€',
    tags: 'Talon tuorejuustoa tomaatti-kerma-cashewpähkinä- mantelijauho-kastikkeessa',
    tags1:
      'Cottage cheese in a creamy tomato, cashew nut, and almond flour sauce',
  },
];

const cocktails = [
  {
    title: 'Nepalilainen tee - Nepalese Tea',
    price: '5€',
    tags: 'Tea with milk spiced with ginger and green cardamom',
  },
  {
    title: 'Nepalilainen tee - Nepalese Tea',
    price: '5€',
    tags: 'Tea with milk spiced with ginger and green cardamom',
  },
  {
    title: 'Nepalilainen tee - Nepalese Tea',
    price: '5€',
    tags: 'Tea with milk spiced with ginger and green cardamom',
  },
  {
    title: 'Nepalilainen tee - Nepalese Tea',
    price: '5€',
    tags: 'Tea with milk spiced with ginger and green cardamom',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Bib Gourmond',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award01,
    title: 'Rising Star',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award05,
    title: 'AA Hospitality',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
  {
    imgUrl: images.award03,
    title: 'Outstanding Chef',
    subtitle: 'Lorem ipsum dolor sit amet, consectetur.',
  },
];

export default { wines, cocktails, awards };
