import React from 'react';

import { images } from '../../constants';
import './AboutUs.css';
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className='app__aboutus flex__center section__padding' id='about'>
      {/* <div className='app__aboutus-overlay flex__center'>
      <img src={images.G} alt='G_overlay' />
    </div> */}

      <div className='app__aboutus-content flex__center'>
        <div className='app__aboutus-content_about'>
          <h1 className='headtext__cormorant'>{t('about_us')}</h1>
          <img src={images.spoon} alt='about_spoon' className='spoon__img' />
          <p className='p__opensans'>{t('about_desc')}</p>
          {/* <button type='button' className='custom__button'>
          Know More
        </button> */}
        </div>

        <div className='app__aboutus-content_knife flex__center'>
          <img src={images.knife} alt='about_knife' />
        </div>

        <div className='app__aboutus-content_history'>
          <h1 className='headtext__cormorant'>{t('history')}</h1>
          <img src={images.spoon} alt='about_spoon' className='spoon__img' />
          <p className='p__opensans'>{t('history_desc')}</p>
          {/* <button type='button' className='custom__button'>
          Know More
        </button> */}
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
