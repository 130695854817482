import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import {
  AboutUs,
  Chef,
  FindUs,
  Footer,
  Gallery,
  Header,
  Intro,
  Laurels,
  SpecialMenu,
} from './container';
import { Navbar } from './components';
import './App.css';
import Alacarte from './container/Alacarte/Alacarte';

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Navbar /> {/* Display Navbar on all pages */}
        <Routes>
          <Route
            path='/'
            element={
              // Home route elements
              <>
                <Header />
                <SpecialMenu />
                <Gallery />
                <AboutUs />
              </>
            }
          />
          <Route path='/menu' element={<Alacarte />} />{' '}
          {/* Menu route for Alacarte */}
          {/* Add routes for other pages if needed */}
        </Routes>
        <Footer /> {/* Display Footer on all pages */}
      </div>
    </BrowserRouter>
  );
};

export default App;
