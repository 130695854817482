import React from 'react';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';

import { SubHeading } from '../../components';
import { images } from '../../constants';
import './Gallery.css';
import { useTranslation } from 'react-i18next';

function Gallery() {
  const scrollRef = React.useRef(null);

  const { t } = useTranslation();

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className='app__gallery flex__center' id='gallery'>
      <div className='app__gallery-content'>
        {/* <SubHeading title="" /> */}
        <h1 className='headtext__cormorant'>{t('photo_gallery')}</h1>
        <br />
        {/* <p className="p__opensans" style={{ color: '#AAAAAA', marginTop: '2rem' }}>
          <h3>Some of our specialities.</h3>
          Take a sneak peak of some of the things that we offer on our menu.<br></br>
          </p>
        <button type="button" className="custom__button">View More</button> */}
      </div>
      <div className='app__gallery-images'>
        <div className='app__gallery-images_container' ref={scrollRef}>
          {[
            images.gall1,
            images.gall2,
            images.gall3,
            images.gall4,
            images.gall5,
            images.gall6,
            images.gall7,
            images.gall8,
            images.gall9,
            images.gall10,
            images.gall11,
            images.gall12,
            images.gall13,
            images.gall14
          ].map((image, index) => (
            <div
              className='app__gallery-images_card flex__center'
              key={`gallery_image-${index + 1}`}
            >
              <img src={image} alt='gallery_image' />
            </div>
          ))}
        </div>
        <div className='app__gallery-images_arrows'>
          <BsArrowLeftShort
            className='gallery__arrow-icon'
            onClick={() => scroll('left')}
          />
          <BsArrowRightShort
            className='gallery__arrow-icon'
            onClick={() => scroll('right')}
          />
        </div>
      </div>
    </div>
  );
}

export default Gallery;
