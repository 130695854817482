import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav className='app__navbar'>
      {location.pathname === '/menu' ? ( // Only show logo on menu route
        <div className='app__navbar-logo'>
          <a href='/'>
            <img src={images.logo_main} alt='app__logo' />
          </a>
        </div>
      ) : (
        <>
          {/* Render logo and navbar links for all other routes */}
          <div className='app__navbar-logo'>
            <a href='/'>
              <img src={images.logo_main} alt='app__logo' />
            </a>
          </div>

          <div className='app__navbar-login'>
            <ul className='app__navbar-links'>
              <li className='p__opensans'>
                <a href='#home'>{t('home')}</a>
              </li>
              <li className='p__opensans'>
                <a href='#menu'>{t('menu')}</a>
              </li>
              <li className='p__opensans'>
                <a href='#gallery'>{t('gallery')}</a>
              </li>
              <li className='p__opensans'>
                <a href='#about'>{t('about')}</a>
              </li>
              {/* <li className="p__opensans"><a href="#awards">Awards</a></li> */}
              <li className='p__opensans'>
                <a href='#footer'>{t('contact')}</a>
              </li>
              <li className='p__opensans dropdown'>
                <ul className='dropdown-menu'>
                  <li>
                    <a href='#' onClick={() => handleLanguageChange('en')}>
                      <img src={images.eng} alt='app__logo' />
                    </a>
                  </li>
                  <li>
                    <a href='#' onClick={() => handleLanguageChange('fi')}>
                      <img src={images.fin} alt='app__logo' />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className='app__navbar-smallscreen'>
            <GiHamburgerMenu
              color='#fff'
              fontSize={27}
              onClick={() => setToggleMenu(true)}
            />
            {toggleMenu && (
              <div className='app__navbar-smallscreen_overlay flex__center slide-bottom'>
                <MdOutlineRestaurantMenu
                  fontSize={27}
                  className='overlay__close'
                  onClick={() => setToggleMenu(false)}
                />
                <ul className='app__navbar-smallscreen_links'>
                  <li>
                    <a href='#home' onClick={() => setToggleMenu(false)}>
                      {t('home')}
                    </a>
                  </li>
                  <li>
                    <a href='#menu' onClick={() => setToggleMenu(false)}>
                      {t('menu')}
                    </a>
                  </li>
                  <li>
                    <a href='#gallery' onClick={() => setToggleMenu(false)}>
                      {t('gallery')}
                    </a>
                  </li>
                  <li>
                    <a href='#about' onClick={() => setToggleMenu(false)}>
                      {t('about')}
                    </a>
                  </li>
                  <li>
                    <a href='#footer' onClick={() => setToggleMenu(false)}>
                      {t('contact')}
                    </a>
                  </li>
                  <li className='p__opensans dropdown'>
                    <ul className='dropdown-menu'>
                      <li>
                        <a href='#' onClick={() => handleLanguageChange('en')}>
                          <img src={images.eng} alt='app__logo' />
                        </a>
                      </li>
                      <li>
                        <a href='#' onClick={() => handleLanguageChange('fi')}>
                          <img src={images.fin} alt='app__logo' />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </nav>
  );
};

export default Navbar;
