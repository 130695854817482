import React from 'react';

import { SubHeading, MenuItem } from '../../components';
import { data, images } from '../../constants';
import './SpecialMenu.css';
import { useTranslation } from 'react-i18next';

function SpecialMenu() {
  const { t } = useTranslation();

  return (
    <div className='app__specialMenu flex__center section__padding' id='menu'>
      <div className='app__specialMenu-title'>
        {/* <SubHeading title='Menu that fits your palatte' /> */}
        <h1 className='headtext__cormorant'>À la carte</h1>
      </div>

      <div className='app__specialMenu-menu'>
        <div className='app__specialMenu-menu_wine  flex__center'>
          {/* <p className='app__specialMenu-menu_heading'>Food</p> */}
          <div className='app__specialMenu_menu_items'>
            {data.wines.map((wine, index) => (
              <MenuItem
                key={wine.title + index}
                title={wine.title}
                price={wine.price}
                tags={wine.tags}
                tags1={wine.tags1}
              />
            ))}
          </div>
        </div>

        {/* 
      <div className='app__specialMenu-menu_img'>
        <img src={images.welcome1} alt='menu__img' />
      </div>

      <div className='app__specialMenu-menu_cocktails  flex__center'>
        <p className='app__specialMenu-menu_heading'>Drinks</p>
        <div className='app__specialMenu_menu_items'>
          {data.cocktails.map((cocktail, index) => (
            <MenuItem
              key={cocktail.title + index}
              title={cocktail.title}
              price={cocktail.price}
              tags={cocktail.tags}
            />
          ))}
        </div>
      </div> 
      */}
      </div>

      <div style={{ marginTop: 15 }}>
        <a href='/menu' target='_blank' rel=''>
          <button type='button' className='custom__button'>
            {t('view_menu')}
          </button>
        </a>
      </div>
    </div>
  );
}

export default SpecialMenu;
