import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'fin',
    resources: {
      en: {
        translation: {
          welcome: 'Authentic Nepali Restaurant',
          welcome_desc:
            'Embark on a culinary adventure to Nepal in the heart of Tampere. Our restaurant offers a delicious taste of Himalayan cuisine, prepared with fresh ingredients and traditional recipes.',
          lunch_buffet: 'LUNCH BUFFET: Mon-Fri 10:30-14:30',
          view_menu: 'Explore Menu',
          home: 'Home',
          about: 'About',
          menu: 'Menu',
          gallery: 'Gallery',
          contact: 'Contact',
          about_us: 'About Us',
          history: 'Our History',
          about_desc:
            "Driven by a love for food and a desire to share our heritage, we bring authentic flavors of Nepal to Tampere. We're passionate about showcasing the unique cuisine of our homeland, offering a delicious taste of Nepal alongside familiar favorites. Years of hard work and dedication culminate in this warm and inviting space, a haven for those seeking the true taste of Nepal. Step inside and be transported to the heart of Nepal itself. Our menu boasts a vibrant selection of dishes, each prepared with fresh, high-quality ingredients steeped in tradition.",
          history_desc:
            "Our story began in Finland as students, eager to explore a new land. Over the decades, Finland became our home. We embraced its culture, learned the language, and even discovered a shared love for flavorful food. We've witnessed the Finnish appreciation for spice, and it sparked a desire to share a special gift: the delicious cuisine of Nepal. This restaurant is our way of giving back to Finland and its wonderful people, offering a taste of our heritage alongside the familiar flavors you've come to love. We invite you to join us on this.",
          photo_gallery: 'Photo Gallery',
          contact_us: 'Contact Us',
          contact_desc:
            'A Delicious Taste Of Himalayan Cuisine, Prepared With Fresh Ingredients And Traditional Recipes.',
          opening_hours: 'Opening Hours',
          right: '2024 Baglung Durbar. All rights reserved.',
          monfri: 'Monday-Friday:',
          satsun: 'Saturday-Sunday:',
        },
      },
      fin: {
        translation: {
          welcome: 'Aitolainen Nepalin Ravintola',
          welcome_desc:
            'Lähde kulinaristiselle seikkailulle Nepalissa Tampereen sydämessä. Ravintolamme tarjoaa herkullisen maun Himalajan keittiöstä, joka on valmistettu tuoreista aineksista ja perinteisistä resepteistä.',
          lunch_buffet: 'LOUNASBUFFET: Ma-Pe 10:30-14:30',
          view_menu: 'Tutustu Menuun',
          home: 'Koti',
          about: 'Tietoa',
          menu: 'Menu',
          gallery: 'Galleria',
          contact: 'Yhteystiedot',
          about_us: 'Meistä',
          history: 'Historiamme',
          about_desc:
            'Rakkaudesta ruokaan ja halusta jakaa perintöämme, tuomme Tampereelle aitoja nepalilaisia makuja. Haluamme esitellä kotimaamme ainutlaatuista keittiötä tarjoamalla herkullisia nepalilaisia makuja tuttujen suosikkien rinnalla. Vuosien kova työ ja omistautuminen huipentuvat tähän lämpimään ja kutsuvaan tilaan, paikkaan niille, jotka etsivät todellista Nepalin makua. Astu sisään ja kuljeta itse Nepalin sydämeen. Ruokalistallamme on eloisa valikoima ruokia, joista jokainen on valmistettu tuoreista, laadukkaista raaka-aineista, jotka ovat täynnä perinteitä.',
          history_desc:
            'Tarinamme alkoi Suomessa opiskelijoista, jotka olivat innokkaita tutustumaan uuteen maahan. Vuosikymmenten aikana Suomesta tuli kotimme. Omaksuimme sen kulttuurin, opimme kielen ja jopa löysimme yhteisen rakkauden maukasta ruokaa kohtaan. Olemme nähneet suomalaisten arvostusta mausteesta, ja se herätti halun jakaa erityinen lahja: Nepalin herkullinen keittiö. Tämä ravintola on tapamme antaa takaisin Suomelle ja sen ihanille ihmisille tarjoten maistiaista perinnöstämme tuttujen makujen rinnalla, joihin olet ihastunut. Kutsumme sinut mukaan tähän.',
          photo_gallery: 'Kuvagalleria',
          contact_us: 'Ota Yhteyttä',
          contact_desc:
            'Herkullinen maku Himalajan keittiöstä, joka on valmistettu tuoreista aineksista ja perinteisistä resepteistä.',
          opening_hours: 'Aukioloajat',
          right: '2024 Baglung Durbar. Kaikki oikeudet pidätetään.',
          monfri: 'Maanantai-perjantai:',
          satsun: 'Lauantai-sunnuntai:',
        },
      },
    },
  });
